import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {TrimVideoPanelProps} from '@Panels/trim-panels/trim-video-panel/trim-video-panel';
import type {TrimData} from '@Panels/trim-panels/trim-panels.types';
import {MODALS_IDS} from '@Components/modal-container';
import {useDispatch} from 'react-redux';
import {LazyPanel} from '@Components/lazy-panel';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'trim-video-modal-panel';

export type TrimVideoModalProps = TrimVideoPanelProps;

export default function TrimVideoModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.TRIM_VIDEO_MODAL];
  });
  const dispatch = useDispatch();

  const height = '710px';
  const width = '1000px';

  return (
    <Modal modalId={MODALS_IDS.TRIM_VIDEO_MODAL} panelId={PANEL_ID} modalHeight={height} mobileHeight={height} isBottomSheetOnMobile modalWidth={width}>
      <LazyPanel
        panelDirectoryName="trim-panels/trim-video-panel"
        panelProps={{
          ...modalData.panelProps,
          onTrim: (trimData: TrimData | null): void => {
            dispatch(closeModal(MODALS_IDS.TRIM_VIDEO_MODAL));
            modalData.panelProps.onTrim(trimData);
          },
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openTrimVideoModal = (props: TrimVideoModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.TRIM_VIDEO_MODAL,
      panelProps: props,
    })
  );
};
